import React, { useState, useEffect } from 'react';
import './Play.css';
import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import io from 'socket.io-client';

const socket = io('http://31.31.196.39:5000');

function Play() {
  const theme = useTheme();
  const isLargeMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('xs'));

  
  const [showGenerateGamePopup, setShowGenerateGamePopup] = useState(false);
  const [showCopyLinkPopup, setShowCopyLinkPopup] = useState(false);
  const [selectedColor, setSelectedColor] = useState('blue');
  const [randomColor, setRandomColor] = useState('gray');
  const [minutes, setMinutes] = useState('1');
  const [seconds, setSeconds] = useState('0');

  const timeControls = [
    { minutes: 10, seconds: 5 },
    { minutes: 15, seconds: 0 },
    { minutes: 20, seconds: 10 },
    { minutes: 30, seconds: 0 },
    { minutes: 3, seconds: 2 },
    { minutes: 5, seconds: 0 },
  ];

  useEffect(() => {
    if (showGenerateGamePopup) {
      setMinutes(1);  // Set the default value for minutes
      setSeconds(0);  // Set the default value for seconds
    }
    setSelectedColor('blue');
  }, [showGenerateGamePopup]);
  
  useEffect(() => {
    socket.on('match_found', (data) => {
      alert(`Matched with player! Player1: ${data.player1}, Player2: ${data.player2}`);
      // Proceed to the game
    });
    
    return () => {
      socket.off('match_found');
    };
  }, []);

  const handleMinutesChange = (e) => {
    const value = e.target.value;
    if (value === '') {
      setMinutes(null);
    } else {
      const minutes = parseInt(value);
      if (minutes < 1) {
        setMinutes(1);
      } else if (minutes > 59) {
        setMinutes(59);
      } else {
        setMinutes(minutes);
      }
    }
  };
  
  const handleSecondsChange = (e) => {
    const value = e.target.value;
    if (value === '') {
      setSeconds(null);
    } else {
      const seconds = parseInt(value);
      if (seconds < 0) {
        setSeconds(0);
      } else if (seconds > 59) {
        setSeconds(59);
      } else {
        setSeconds(seconds);
      }
    }
  };
  

  const handleSectorClick = async (minutes, seconds) => {
    console.log(`Searching for players with ${minutes} minutes per game and ${seconds} seconds per turn...`);

    const time_control = `${minutes}:${seconds}`;

    try {
      const response = await fetch('http://31.31.196.39:5000/matchmaking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ time_control }),
      });

      const data = await response.json();

      if (data.match_found) {
        console.log('Match found!');
        // Match found logic
      } else {
        console.log(data.response);
        // Handle waiting logic (e.g., showing a loading spinner)
      }
    } catch (error) {
      console.error('Error during matchmaking:', error);
    }
  };

  const handleCreateGameButton = () => {
    console.log("Creating game link...");
    setShowGenerateGamePopup(true);
  };

  const handleRandomColor = () => {
    const colors = ['pink', 'black', 'orange', 'green', 'purple', 'yellow', 'indigo'];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    setRandomColor(randomColor);
    setSelectedColor(randomColor);
  };
  
  const handleColorSelect = (color) => {
    setSelectedColor(color);
    console.log(selectedColor);
  }
  
  useEffect(() => {
    // Log the selected color
    console.log(selectedColor);
  
    // DOM manipulation to highlight the selected color
    const options = document.querySelectorAll('.color-option');
    options.forEach((option) => {
      option.classList.remove('selected');
    });
  
    const selectedOption = document.querySelector(`.color-option[data-color="${selectedColor}"]`);
    if (selectedOption) {
      selectedOption.classList.add('selected');
    }
  }, [selectedColor]);
  
  

  const handleCreateGame = () => {
    //create game with time sessions
    console.log(`Creating game with ${minutes} minutes, ${seconds} seconds, and ${selectedColor} color...`);

    //remove current popup to show the next
    setShowGenerateGamePopup(false);
    setShowCopyLinkPopup(true);
  }

  const handleCopyLink = () => {
    // copy link to clipboard
    setShowCopyLinkPopup(false);
  }

  return (
    <div className='hexagon-container'>
      <svg width="360" height="400">
        {timeControls.map((timeControl, index) => {
          const sectorAngle = 360 / timeControls.length;
          const startAngle = index * sectorAngle;
          const endAngle = startAngle + sectorAngle;
          const startX = 200 + 150 * Math.cos(startAngle * Math.PI / 180);
          const startY = 200 + 150 * Math.sin(startAngle * Math.PI / 180);
          const endX = 200 + 150 * Math.cos(endAngle * Math.PI / 180);
          const endY = 200 + 150 * Math.sin(endAngle * Math.PI / 180);
          const textAngle = (startAngle + endAngle) / 2;
          const textX = 200 + 90 * Math.cos(textAngle * Math.PI / 180);
          const textY = 200 + 90 * Math.sin(textAngle * Math.PI / 180);

          return (
            <g key={index}>
              <path
                d={`M ${startX},${startY} L ${endX},${endY} L 200,200 Z`}
                fill={`hsl(${index * 60}, 60%, 50%)`}
                onClick={() => handleSectorClick(timeControl.minutes, timeControl.seconds)}
              />
              <text
                x={textX}
                y={textY}
                fontSize="14"
                textAnchor="middle"
              >
                {`${timeControl.minutes}+${timeControl.seconds}`}
              </text>
            </g>
          );
        })}
      </svg>
      {isLargeMobile || isSmallMobile ? (
        <div onClick={handleCreateGameButton} className="hex-mobile-button">
          <svg viewBox="0 0 100 86.6" width="150">
            <polygon className="hex-polygon" points="25,0 75,0 100,43.3 75,86.6 25,86.6 0,43.3" fill="#0b1422" stroke='#ffffff' strokeWidth="2" />
            <text x="50%" y="52%" dominantBaseline="middle" textAnchor="middle" fill="white" fontSize="10" fontFamily="Arial" align="middle">
              <tspan x="50%" dy="-8">Create</tspan>
              <tspan x="50%" dy="1.2em">Game</tspan>
            </text>
          </svg>
        </div>
      ) : (
        <div onClick={handleCreateGameButton} className="hex-button">
          <svg viewBox="0 0 100 86.6" width="150">
            <polygon className="hex-polygon" points="25,0 75,0 100,43.3 75,86.6 25,86.6 0,43.3" fill="#0b1422" stroke='#ffffff' strokeWidth="2" />
            <text x="50%" y="52%" dominantBaseline="middle" textAnchor="middle" fill="white" fontSize="10" fontFamily="Arial" align="middle">
              <tspan x="50%" dy="-8">Create</tspan>
              <tspan x="50%" dy="1.2em">Game</tspan>
            </text>
          </svg>
        </div>
      )

      }
      {showGenerateGamePopup && (
        <div className="generate-game-popup">
          <div className="generate-game-popup-content">
            <div className="generate-game-popup-row1">
              <Typography variant="body1">Enter time control: </Typography>
              <input type="number" placeholder="min" onChange={handleMinutesChange} min="1" max="59" value={minutes}/>
              <input type="number" placeholder="sec" onChange={handleSecondsChange} min="0" max="59" value={seconds}/>
            </div>
            <div className="generate-game-popup-row2">
              <Typography variant="body1">Choose color:</Typography>
              <div
                className={`color-option ${selectedColor === 'blue' ? 'selected' : ''}`}
                data-color="blue"
                onClick={() => handleColorSelect('blue')}
                style={{ backgroundColor: 'blue' }}
              >
                Blue
              </div>
              <div
                className={`color-option ${selectedColor === 'red' ? 'selected' : ''}`}
                data-color="red"
                onClick={() => handleColorSelect('red')}
                style={{ backgroundColor: 'red' }}
              >
                Red
              </div>
              <div
                className={`color-option ${selectedColor === randomColor ? 'selected' : ''}`}
                data-color={randomColor}
                onClick={handleRandomColor}
                style={{ backgroundColor: randomColor }}
                key={randomColor}
              >
                Any
              </div>
            </div>
            <div className="generate-game-popup-row3">
              <button 
                className="generate-game-popup-ready-button"
                onClick={handleCreateGame} 
                disabled={!(minutes !== null && minutes !== '' && seconds !== null && seconds !== '' && selectedColor)}
              >
                Ready
              </button>
            </div>
          </div>
        </div>
      )}

      {showCopyLinkPopup && (
        <div className="copy-link-popup">
          <div className='copy-link-popup-content'>
            <Typography variant="body1">Copy link and send it to anyone:</Typography>
            <button
              className='copy-link-button'
              onClick={handleCopyLink} 
            >
              Copy Link
            </button>
          </div>
        </div>
      )}
    </div>

  );
}

export default Play;
