import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff1744', // Bright red for a game feel
    },
    secondary: {
      main: '#1de9b6', // Bright teal for contrast
    },
  },
  typography: {
    fontFamily: 'Press Start 2P, sans-serif', // A retro game font
  },
});

export default theme;
