import React from 'react';
import './Components.css'; 

function Ball({ position, color, onClick }) {
  return (
    <div 
      className={`ball ${color}`} 
      style={{ top: position.top, left: position.left }}
      onClick={onClick}
    >
    </div>
  );
}

export default Ball;
