// BallLayout.js
const ballPositions = (rows = 4, columns = 6, spacing = 40) => {
    const positions = [];
    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < columns; col++) {
        const top = row * spacing;
        const left = col * spacing;
        positions.push({ top, left });
      }
    }
    return positions;
  };
  
  export default ballPositions;
  