import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme, useMediaQuery, IconButton, Menu, MenuItem } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import './Navbar.css';

function Navbar() {
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isLargeMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/about" className="navbar-logo">
          Quantum
        </Link>

        { isSmallMobile || isLargeMobile ? (
          <>
            <IconButton 
              edge="start" 
              color="inherit" 
              aria-label="menu" 
              onClick={handleMenuOpen}
              sx={{
                color: '#FFFFFF',
              }}
              size='small'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              keepMounted
              sx={{
                '& .MuiPaper-root': {
                  backgroundColor: '#0b1422',

                },
              }}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link to="/play" className="navbar-link">
                  Play
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/analyse" className="navbar-link">
                  Analyse
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/learn" className="navbar-link">
                  Learn
                </Link>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <ul className="navbar-menu">
            <li>
              <Link to="/play" className="navbar-link">
                Play
              </Link>
            </li>
            <li>
              <Link to="/analyse" className="navbar-link">
                Analyse
              </Link>
            </li>
            <li>
              <Link to="/learn" className="navbar-link">
                Learn
              </Link>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
