// src/AppRouter.js
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './Navbar';
import About from '../pages/About';
import Play from '../pages/menu/Play';
import Analyse from '../pages/menu/Analyse';

const AppRouter = () => {
  return (
    <BrowserRouter>
        <Navbar />
        <Routes>
            <Route path="/about" element={<About/>} />
            <Route path="/" element={<Navigate to="/about" replace />} />

            <Route path="/play" element={<Play />} />
            <Route path="/analyse" element={<Analyse />} />
        </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;