// src/pages/About.js
import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';

const AboutContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
`;

const AboutContent = styled(Box)`
  background: rgba(255, 255, 255, 0.5);
  color: #ffffff;
  border-radius: 15px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 1000px;
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const Heading = styled(Typography)`
  font-size: 3rem;
  margin-bottom: 20px;
  color: #ff1744;
  text-shadow: 2px 2px #1de9b6;
`;

const Paragraph = styled(Typography)`
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 1000px;
  color: #f5f5f5;
  text-shadow: 1px 1px #333;
  &:hover {
    color: #1de9b6;
    text-shadow: 2px 2px #ff1744;
    transition: all 0.3s ease-in-out;
  }
`;

const SocialLink = styled('a')`
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #1de9b6;
    color: #ff1744;
  }
`;

const SocialLinks = styled(Box)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
`;


function About() {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isXl = useMediaQuery(theme.breakpoints.down('xl'));
  const isXXl = useMediaQuery(theme.breakpoints.down('xxl'));

  const responsiveStyles = getResponsiveStyles(isXs, isSm, isMd, isLg, isXl, isXXl);

  return (
    <AboutContainer sx={{ ...responsiveStyles }}>
      <AboutContent sx={{ ...responsiveStyles, padding: responsiveStyles.padding + ' 20px' }}>
        <Heading variant="h1">About Us</Heading>
        <Paragraph variant="body1">
            Welcome to Quantum Quest, the pioneering game development studio that's pushing the boundaries of quantum gaming!
        </Paragraph>
        <Paragraph variant="body1">
          Our team of passionate gamers, physicists, and developers came together with a shared vision: to create immersive, mind-bending experiences that harness the power of quantum mechanics. We believe that the principles of superposition, entanglement, and uncertainty can be leveraged to craft games that are not only fun but also thought-provoking and educational.
        </Paragraph>
        <Paragraph variant="body1">
          Our debut game, Quantum, is the culmination of our research and innovation. This quantum-inspired adventure takes you on a journey through the mysteries of the subatomic realm, where you'll encounter strange creatures, solve puzzles, and unravel the secrets of the quantum universe.
        </Paragraph>
        <Paragraph variant="body1">
          At Quantum Quest, we're committed to creating games that inspire curiosity, foster a sense of community, and promote STEM education. Join us on this exciting journey as we explore the uncharted territories of quantum gaming!
        </Paragraph>
      </AboutContent>

      <SocialLinks sx={{ gap: responsiveStyles.gap }}>
        <SocialLink href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </SocialLink>
        <SocialLink href="https://facebook.com/yourprofile" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </SocialLink>
        <SocialLink href="https://instagram.com/yourprofile" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </SocialLink>
        <SocialLink href="https://linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </SocialLink>
      </SocialLinks>
    </AboutContainer>
  );
}

const getResponsiveStyles = (isXs, isSm, isMd, isLg, isXl, isXXl) => {
  let styles = {};

  if (isXs) {
    styles = {
      maxWidth: '300px',
      padding: '10px',
      gap: '5px',
    };
  } else if (isSm) {
    styles = {
      maxWidth: '500px',
      padding: '20px',
      gap: '10px',
    };
  } else if (isMd) {
    styles = {
      maxWidth: '700px',
      padding: '30px',
      gap: '15px',
    };
  } else if (isLg) {
    styles = {
      maxWidth: '900px',
      padding: '40px',
      gap: '20px',
    };
  } else if (isXl) {
    styles = {
      maxWidth: '1100px',
      padding: '50px',
      gap: '25px',
    };
  } else if (isXXl) {
    styles = {
      maxWidth: '1300px',
      padding: '60px',
      gap: '30px',
    };
  }

  return styles;
};


export default About;
