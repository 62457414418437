// Analyse.js
import React, { useState } from 'react';
import Ring from './components/Ring';
import Ball from './components/Ball';
import hexagonPositions from './components/HexagonLayout';
import ballPositions from './components/BallLayout';
import './Analyse.css';

function Analyse() {
  const ringPositions = hexagonPositions();
  const ballPositionsArray = ballPositions(); // Generate ball positions

  const initialBalls = [
    ...Array(6).fill('white'),
    ...Array(8).fill('gray'),
    ...Array(10).fill('black'),
  ].map((color, index) => ({ id: index, color, position: null }));

  const [balls, setBalls] = useState(initialBalls);
  const [selectedBall, setSelectedBall] = useState(null);

  const handleBallClick = (ball) => {
    if (!ball.position) {
      setSelectedBall(ball);
    }
  };

  const handleRingClick = (position) => {
    if (selectedBall) {
      setBalls(balls.map(ball =>
        ball.id === selectedBall.id ? { ...ball, position } : ball
      ));
      setSelectedBall(null);
    }
  };

  return (
    <div className='analyse'>
      <div className="game-board">
        <div className="ring-layout-board">
          {ringPositions.map((pos, index) => (
            <Ring key={index} position={pos} onClick={() => handleRingClick(pos)} />
          ))}
          {balls.map(ball =>
            ball.position && (
              <Ball key={ball.id} position={ball.position} color={ball.color} />
            )
          )}
        </div>
        <div className="ball-layout-board">
          {balls.map((ball, index) =>
            !ball.position && (
              <Ball
                key={ball.id}
                position={ballPositionsArray[index]} // Use ball layout positions
                color={ball.color}
                onClick={() => handleBallClick(ball)}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default Analyse;
