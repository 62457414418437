const hexagonPositions = () => {
    const positions = [];
    const radius = 28;
    for (let q = -3; q <= 3; q++) {
      for (let r = Math.max(-3, -q - 3); r <= Math.min(3, -q + 3); r++) {
        const x = radius * 3/2 * q;
        const y = radius * Math.sqrt(3) * (r + q/2);
        positions.push({ top: y, left: x });
      }
    }
    return positions;
  };
  
  export default hexagonPositions;
  