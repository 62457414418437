import React from 'react';
import './Components.css';

function Ring({ position, onClick }) {
  return (
    <div 
      className='ring' 
      style={{ top: position.top, left: position.left }}
      onClick={onClick}
    ></div>
  );
}

export default Ring;
